import croppedClamp from "./images/croppedClamp.png";
import inUse from "./images/inUse.JPG";
import viseView from "./images/viseView.JPG";
import pullTest from "./images/pullTest.JPG";
import onTable from "./images/onTable.JPG";
import movieOne from "./images/movieOne.mov";
import movieTwo from "./images/movieTwo.MOV";
import tiktok from "./images/tiktok.mov";

function App() {
  return (
    <>
      {/* Navbar */}
      <header class="text-gray-600 body-font">
        <div class="container mx-auto flex flex-wrap p-5 flex-col items-center">
          <a class="flex title-font font-bold items-center text-gray-900 mb-4 md:mb-0">
            <span class="ml-3 text-xl">Daniels Universal Polish Rod Clamp</span>
          </a>
          {/* <nav class="md:ml-auto flex flex-wrap items-center text-base justify-center">
            <a class="mr-5 hover:text-gray-900">First Link</a>
            <a class="mr-5 hover:text-gray-900">Second Link</a>
          </nav> */}
        </div>
      </header>

      {/* video and hero */}
      <section class="text-gray-600 body-font">
        <div class="container mx-auto flex flex-wrap py-6 flex-col md:flex-row">
          <video
            src={movieOne}
            class="w-3/4 md:w-2/5 mx-auto p-5"
            frameborder="0"
            autoPlay
            loop
            muted
          ></video>

          <div class="w-full md:w-1/2 self-center p-5">
            <div class="w-full">
              <h1 class="title-font sm:text-4xl text-4xl mb-4 font-medium text-red-500">
                Daniels Universal Polish Rod Clamp
              </h1>
              <p class="mb-8 leading-relaxed">
                The Daniels Universal Polish Rod Clamp changes the way oil
                rigging is done.
              </p>
              <img
                class="w-1/2 md:w-2/3 object-cover mx-auto rounded"
                alt="hero"
                src={croppedClamp}
              />
            </div>
          </div>
        </div>
      </section>
      <section class="text-gray-600 body-font">
        <div class="container mx-auto flex flex-wrap p-5 flex-col md:flex-row">
          <div class="md:w-2/5 text-center mx-auto">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              class="inline-block w-8 h-8 text-gray-400 mb-8"
              viewBox="0 0 975.036 975.036"
            >
              <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
            </svg>
            <p class="leading-relaxed text-lg">
              My experience on the oil rigs led me to this ingenious design. It
              saw a, b, and see on the rigs and knew there was a better way to
              do things. This field-tested innovation increases efficiency, cuts
              down-time, and highens job safety. It's a must for any oil site.
            </p>
            <span class="inline-block h-1 w-10 rounded bg-red-500 mt-8 mb-6"></span>
            <h2 class="text-gray-900 font-medium title-font tracking-wider text-sm">
              MIKE DANIELS
            </h2>
            <p class="text-gray-500">Senior Product Designer</p>
          </div>

          <video
            src={tiktok}
            class="w-3/4 md:w-2/5 mx-auto p-5"
            frameborder="0"
            autoPlay
            loop
            muted
          ></video>
        </div>
      </section>

      {/* Feature */}
      <section class="text-gray-600 body-font">
        <div class="container px-5 py-24 mx-auto">
          <div class="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
            <div class="p-4 md:w-1/2 flex flex-col text-center items-center">
              <div class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-red-100 text-red-500 mb-5 flex-shrink-0">
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  class="w-10 h-10"
                  viewBox="0 0 24 24"
                >
                  <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                </svg>
              </div>
              <div class="flex-grow">
                <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                  Pull Test Rated
                </h2>
                <p class="leading-relaxed text-base">
                  Pull tested at a weight of 81,600 pounds, 40 tons.
                </p>
              </div>
            </div>
            <div class="p-4 md:w-1/2 flex flex-col text-center items-center">
              <div class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-red-100 text-red-500 mb-5 flex-shrink-0">
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  class="w-10 h-10"
                  viewBox="0 0 24 24"
                >
                  <circle cx="6" cy="6" r="3"></circle>
                  <circle cx="6" cy="18" r="3"></circle>
                  <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
                </svg>
              </div>
              <div class="flex-grow">
                <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                  Universal
                </h2>
                <p class="leading-relaxed text-base">
                  Fits all polish rod by just changing slips to what size of rod
                  don’t mark the polish rod and no torque needed the weight of
                  the rods get it torque
                </p>
              </div>
            </div>
          </div>

          <a href="mailto:miked84001@hotmail.com">
            <button class="flex mx-auto mt-16 text-white bg-red-500 border-0 py-2 px-8 focus:outline-none hover:bg-red-600 rounded text-lg">
              Contact Owner
            </button>
          </a>

          <div class="flex mt-6 justify-center">
            <div class="w-16 h-1 rounded-full bg-red-500 inline-flex"></div>
          </div>
        </div>
      </section>

      {/* Image Gallery */}
      <section>
        <div class="container px-5  mx-auto">
          <h1 class="title-font sm:text-4xl text-4xl font-medium text-red-500">
            Additional Views
          </h1>
        </div>

        <div class="container mx-auto h-auto flex flex-wrap">
          <video
            src={movieTwo}
            class="w-1/2 p-5"
            frameborder="0"
            autoPlay
            loop
            muted
          ></video>
          <img alt="gallery" class="w-1/2 p-5" src={onTable} />
          <img alt="gallery" class="w-1/2 p-5" src={viseView} />
          <img alt="gallery" class="w-1/2 p-5" src={pullTest} />
          <img alt="gallery" class="w-1/2 p-5" src={inUse} />
        </div>
      </section>
    </>
  );
}

export default App;
